<template>

    <div class="plafonds">
    <barre-navigation></barre-navigation>

    <div class="specialitePlafonds">
        <div class="blocPlafonds">
            <div class="centrerPlafonds">
                <div class="titrePlafonds">
                    <h1> Faux Plafonds </h1>
                    <div class="lignePlafonds"></div>
                </div>
                <div class="descriptionPlafonds">
                    <p> Le <span> faux plafond </span> ou plafond suspendu présente de nombreux avantages, tant au niveau de l'isolation thermique et phonique qu'au niveau esthétique.
                    Il est très utilisé dans les logements anciens pour <span> isoler du bruit </span> provenant de l’étage supérieur mais aussi pour optimiser l’ <span> isolation thermique </span> d’une pièce en abaissant le plafond. <br> <br>
                    Il permet également d'<span> aménager un espace libre </span> utile pour insérer conduits et fils électriques ou encore leds ou rampes lumineuses pour optimiser l'éclairage de la pièce et la rendre chaleureuse.  
                    Il a aussi pour avantage de <span> cacher les irrégularités </span> du plafond d’origine ou de délimiter un espace.
                    </p>
                </div>
            </div>
            <div class="imgPlafonds">
                <img alt="image-fauxplafond" src="../assets/fauxplafond2.png">
                <img alt="image-fauxplafond" src="../assets/fauxplafond1.png">
            </div>
        </div>
    </div>
</div>

</template>

<script>

    import BarreNavigation from '../components/BarreNavigation.vue'

    export default {
        name: 'Fauxplafonds',
        components: {
        BarreNavigation,
        }
    }

</script>

<style scoped>

    .specialitePlafonds {

        padding-right: 14rem;
        padding-left: 14rem;

    }

    .blocPlafonds {

        padding: var(--padding-haut-bas-section);

    }

    .blocPlafonds h1 {

        font-size: 36px;
        font-weight: bold;
        color: #333F50;
        padding-bottom: 40px;

    }

    .blocPlafonds p {

        font-size: 16px;
        color: #333F50;

    }

    .centrerPlafonds {

        display: flex;
        justify-content: space-evenly;

    }

    .lignePlafonds {

        background-color: #7C928F;
        height: 10px;
        width: 400px;

    }

    .descriptionPlafonds {

        margin-left: 50px;
        font-size: 20px;

    }

    .descriptionPlafonds p {

        line-height: var(--line-height-pagesdetails);

    }

    .descriptionPlafonds span {

        font-weight: bold;

    }

    .imgPlafonds {

        display: flex;
        justify-content: space-around;
        padding-top: 40px;

    }

    .imgPlafonds img:nth-child(1) {

        height: 42.5%;
        width: 42.5%;
        align-self: center;

    }

    .imgPlafonds img:nth-child(2) {

        height: 28%;
        width: 28%;

    }

    @media screen and (max-width: 1400px) {

        .descriptionPlafonds p {

            line-height: var(--line-height-medium);

        }

    }


    @media screen and (max-width: 1300px) {

        .lignePlafonds {
            
            width: 300px;

        }

        .imgPlafonds {

            padding-top: 60px;

        }

        .imgPlafonds img {

            height: 90%;
            width: 90%;

        }

    }

    @media screen and (max-width: 1110px) {

        .centrerPlafonds {

            flex-direction: column;
            align-items: center;

        }

        .titrePlafonds h1 {

            text-align: center;

        }

        .lignePlafonds {

            width: 500px;
            height: 8px;

        }

        .descriptionPlafonds {

            padding-top: 40px;
            display: flex;
            justify-content: center;
            margin-left: 0px;

        }

        .descriptionPlafonds p {

            width: 500px;
            text-align: justify;

        }

        .imgPlafonds img {

            height: 85%;
            width: 85%;

        }

    }

    @media screen and (max-width: 1000px) {

        .imgPlafonds {

            justify-content: space-between;

        }

        .imgPlafonds img {

            padding-top: 50px;

        }

        .imgPlafonds img:nth-child(1) {

            height: 293px;
            width: 325px;

        }

        .imgPlafonds img:nth-child(2) {

            height: 293px;
            width: 213px;

        }

    }

    @media screen and (min-width: 670px) and (max-width: 870px) {

        .blocPlafonds {

            padding-top: 60px;

        }

    }


    @media screen and (max-width: 830px) {

        .imgPlafonds {

            flex-direction: column;
            align-items: center;
            padding-top: 0px;

        }

    }

    @media screen and (max-width: 550px) {

        .titrePlafonds h1 {

            font-size: 28px;
            text-align: center;
            padding-bottom: 30px;

        }

        .lignePlafonds {

            width: 300px;
            height: 6px;

        }

        .descriptionPlafonds p {

            width: 300px;
            font-size: 14px;

        }

        .imgPlafonds img:nth-child(1) {

            height: 229px;
            width: 254px;

        }

        .imgPlafonds img:nth-child(2) {

            height: 229px;
            width: 166.5px;

        }

    }

</style>